import React from 'react'
import { MrModal, clearModal } from '@hixme/modal'
import { connect } from 'react-redux'

import { Stack, Typography } from '@sureco/design-system'
import { Spin } from '@surecompanies/core_components'
import { LoadingOutlined } from '@ant-design/icons'
// hixme-ui
import notification from 'modules/notification-manager'
import { selectors as personsSelectors } from 'store/modules/persons'
import { getEmployee } from 'store/modules/persons/selectors'
import { getEnrollmentSession } from 'store/modules/user-session/selectors'

// project
import { useQuery } from '@apollo/client'
import { GET_MEDICARE_COVERAGE } from 'apollo/queries'
import { actions as cartActions } from 'store/modules/cart'
import { MedicarePlanForm, MedicareCoverageDetail, MedicarePerson, MedicarePlanFormPayload } from './MedicarePlanForm'
import { getInitialMedicarePlanFormState } from './helper'

export type GetMedicareCoverageResponse = {
  getMedicareCoverage: MedicareCoverageDetail[]
}

export type MedicareModalProps = {
  employee: MedicarePerson,
  enrollment: { Id: string },
  dependents: MedicarePerson[],
  onSubmit: (payload: MedicarePlanFormPayload) => void,
  onUploadSuccess?: () => void,
  onUploadError?: (error: any) => void,
  onClose: () => void,
}

const MedicareModal = ({
  employee,
  enrollment,
  dependents,
  onSubmit,
  onUploadSuccess,
  onUploadError,
  onClose
}: MedicareModalProps) => {
  const { data, loading, error } = useQuery<GetMedicareCoverageResponse>(GET_MEDICARE_COVERAGE, {
    variables: {
      enrollmentPublicKey: enrollment.Id,
    }
  })

  const renderLoading = () => {
    return (
      <Stack style={{ padding: 16, justifyContent: 'center', alignItems: 'center' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </Stack>
    )
  }

  const renderError = () => {
    return (
      <Stack style={{ padding: 16, alignItems: 'center' }} space={8}>
        <Typography size={16} color="error">Sorry, an error has occurred.</Typography>
        <Typography size={14} color="error">Please contact <a href="mailto:employee.experience@sureco.com">employee.experience@sureco.com</a> for assistance.</Typography>
      </Stack>
    )
  }

  const render = () => {
    if (data) {
      const defaultValue = getInitialMedicarePlanFormState(data.getMedicareCoverage, employee, dependents)
      const eligibleMembers = Object.values(defaultValue.members).map(m => m.person)
      return <MedicarePlanForm
        defaultValue={defaultValue}
        employee={employee}
        enrollment={enrollment}
        eligibleMembers={eligibleMembers}
        medicareCoverages={data.getMedicareCoverage}
        onSubmit={onSubmit}
        onUploadSuccess={onUploadSuccess}
        onUploadError={onUploadError}
        onClose={onClose}
      />
    } else if (loading) {
      return renderLoading()
    } else {
      console.error(error);
      return renderError()
    }
  }

  return (
    // shouldCloseOnOverlayClick false because confirmation only saves one member at a time
    // so want to avoid accidental closes and enable cancel prompt if unsaved changes are present
    <MrModal small hideCloseButton shouldCloseOnOverlayClick={false}>
      {
        render()
      }
    </MrModal>
  )
}

const mapStateToProps = (state) => {
  const employee = getEmployee(state)
  const enrollment = getEnrollmentSession(state)
  const dependents = personsSelectors.getDependents(state)

  return {
    dependents,
    employee,
    enrollment,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (confirmData) => {
    return dispatch(cartActions.UpdateMedicare(confirmData))
  },
  onUploadSuccess: async () => {
    dispatch(
      notification.actions.createNotification({
        type: 'success',
        autoClose: true,
        message: `File was successfully uploaded`,
      })
    )
  },
  onUploadError: async (error) => {
    dispatch(
      notification.actions.createNotification({
        type: 'error',
        autoClose: true,
        message: `File upload failed. Error ${error?.message ?? ''}`,
      })
    )
  },
  onClose: () => dispatch(clearModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MedicareModal)
