import React, { useRef, useState } from 'react'
import { v4 } from 'uuid'

import { Stack, Typography, Modal, ModalAPI } from '@sureco/design-system'
import { Button, Input, InputNumber } from '@surecompanies/core_components'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
// hixme-ui
import RadioButton from '@hixme-ui/radio-button'

import { useMedicareDocumentsUpload } from './useMedicareDocumentsUpload'
import { currencyFormatter } from 'routes/Expenses/utils/expenses'

export type UploadedDocument = { key: string, preSignedUrl: string }

export const MedicarePlanTypeNames = {
    A: 'Plan A',
    B: 'Plan B',
    C: 'Plan C',
    D: 'Plan D',
    Other: 'Other'
} as const

export type MedicarePlanType = (typeof MedicarePlanTypeNames)[keyof typeof MedicarePlanTypeNames];

export type MedicareCoveragePlan = {
    planType: MedicarePlanType;
    premium: number;
    carrier: string;
    effectiveDate: string;
    insuredId: string;
    insuredName: string;
    planId: string;
    planName: string;
};

export type MedicareCoverageDetail = {
    employeePublicKey: string;
    personId: string;
    medicareNumber: string;
    medicareCoverage: MedicareCoveragePlan[];
    documents: UploadedDocument[];
    status: string
    approvalDate: string | null;
    validatedTotalMedicareCost: number | null;
    totalReimbursementAmount: number | null;
    approvedById: string | null;
    approvedByName: string | null;
    updatedDate: string;
    coverageDate: string | null;
};

export type MedicarePlanState = {
    uuid: string // unique id only for UI (cuz # of plans is dynamic)
    planType: MedicarePlanType,
    premium: number,
}

export type MedicarePerson = {
    Id: string,
    Relationship: string,
    FullName: string,
    EmployeePublicKey: string,
    [key: string]: any,
}

export type MedicareMemberState = {
    medicareNumber: string,
    person: MedicarePerson,
    plans?: MedicarePlanState[]
    uploads?: UploadedDocument[]
    touched?: boolean // only used on FE for UX
}

export type MedicarePlanFormState = {
    selectedMemberId: string,
    members: Record<string, MedicareMemberState>
}

export type MedicarePlanFormPayload = {
    personId: string,
    enrollmentPublicKey: string,
    personPublicKey: string,
    medicareNumber: string,
    medicareCoverage: Omit<MedicarePlanState, 'uuid'>[],
    documents: UploadedDocument[],
    insuredName: string,
    insuredId: string,
}

export type PlanBlockProps = {
    count: number,
    plan: MedicarePlanState,
    onPlanTypeChange: (planType: string, plan: MedicarePlanState) => void,
    onPremiumChange: (premium: number, plan: MedicarePlanState) => void,
    onRemovePlan: (plan: MedicarePlanState) => void,
}

export const PlanBlock = ({
    count,
    plan,
    onPlanTypeChange,
    onPremiumChange,
    onRemovePlan,
}: PlanBlockProps) => {
    const PlanTypeOptions = [MedicarePlanTypeNames.A, MedicarePlanTypeNames.B, MedicarePlanTypeNames.C, MedicarePlanTypeNames.D, MedicarePlanTypeNames.Other] as const;
    return (
        <div
            style={{
                margin: '20px 0',
                borderTop: '1px solid #fafafa',
                borderColor: '#acacac',
                padding: '10px 0',
            }}
        >
            <Stack direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography bold size={14}>
                    Plan type {count + 1}
                </Typography>
                {count !== 0 && (
                    <Button danger onClick={() => { onRemovePlan(plan) }}>
                        Remove
                    </Button>
                )}
            </Stack>
            <Stack direction="row" style={{ padding: '5px 0', marginBottom: '10px' }}>
                {PlanTypeOptions.map((type: MedicarePlanType, index) => {
                    return (
                        <RadioButton
                            key={`${type}-${plan.uuid}`}
                            style={{ width: index === 4 ? '120px' : '110px', marginRight: '10px' }}
                            name={`plan-type-input-${plan.uuid}`}
                            text={type}
                            value={type}
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>) => onPlanTypeChange(event.target.value as MedicarePlanType, plan)
                            }
                            checked={type === plan.planType}
                        />
                    )
                })}
            </Stack>
            <Stack space={5}>
                <Typography bold size={14}>
                    Premium (monthly)
                </Typography>
                <InputNumber
                    name="Premium"
                    controls={false}
                    defaultValue={plan.premium.toString()}
                    formatter={(value) =>
                        value === '' ? '' : `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => onPremiumChange(Number(value) || 0, plan)}
                    size="middle"
                    style={{ width: '100px' }}
                    maxLength={9}
                />
                <Typography>
                    Enter the monthly premium cost for the selected plan. If there is no cost, please enter
                    $0. If your bill is for more than one month, please enter ONLY the premium cost for a
                    single month.
                </Typography>
            </Stack>
        </div>
    )
}

export type MedicarePlanFormProps = {
    defaultValue: MedicarePlanFormState,
    employee: MedicarePerson,
    eligibleMembers: MedicarePerson[],
    enrollment: { Id: string, [key: string]: any },
    medicareCoverages: MedicareCoverageDetail[]
    onSubmit: (payload: MedicarePlanFormPayload) => void,
    onUploadSuccess?: () => void,
    onUploadError?: (error: any) => void,
    onClose: () => void,
}

export const MedicarePlanForm = ({
    defaultValue,
    employee,
    eligibleMembers,
    medicareCoverages,
    onSubmit,
    onUploadSuccess,
    onUploadError,
    enrollment,
    onClose,
}: MedicarePlanFormProps) => {
    const { generateFileUrl, uploadFile } = useMedicareDocumentsUpload()
    const [form, setForm] = useState<MedicarePlanFormState>(defaultValue)
    const [uploading, setUploading] = useState(false)
    const [confirming, setConfirming] = useState(false)
    const [error, setError] = useState(null)
    const inputFile = useRef(null)

    const selectedData = form.members[form.selectedMemberId]
    const coverage = medicareCoverages.find(cov => cov.personId === form.selectedMemberId)

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0]
        if (!file) {
            setError('Please select a file to upload.')
            return
        }
        setUploading(true)
        setError(null)

        try {
            const {
                data: { generateMedicareCoveragePreSignedUrl },
            } = await generateFileUrl({
                variables: {
                    input: {
                        personPublicKey: employee.EmployeePublicKey,
                        extension: file.name.split('.').pop(),
                    },
                },
            })

            await uploadFile(generateMedicareCoveragePreSignedUrl.preSignedUrl, file)
            addUploadedFiles({ key: generateMedicareCoveragePreSignedUrl.fileKey, preSignedUrl: generateMedicareCoveragePreSignedUrl.preSignedUrl })
            onUploadSuccess?.()
        } catch (err) {
            onUploadError?.(err)
            setError('Failed to upload file.')
        } finally {
            setUploading(false)
        }
    }

    const setSelectedMemberFormState = (key: keyof MedicareMemberState, value: any | ((prev: any) => any)) => {
        setForm(prev => ({
            selectedMemberId: prev.selectedMemberId,
            members: {
                ...prev.members,
                [prev.selectedMemberId]: {
                    ...prev.members[prev.selectedMemberId],
                    touched: true,
                    [key]: typeof value === 'function' ? value(prev.members[prev.selectedMemberId][key]) : value,
                }
            }
        }))
    }

    const onMemberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setForm((prev) => ({
            ...prev,
            selectedMemberId: event.target.value,
        }))
    }

    const onPlanTypeChange = (planType: MedicarePlanType, current: MedicarePlanState) => {
        setSelectedMemberFormState('plans', (prev: MedicarePlanState[]): MedicarePlanState[] => {
            return prev.map(plan => {
                if (plan.uuid === current.uuid) {
                    return { ...plan, planType }
                }
                return plan
            })
        })
    }

    const onPremiumChange = (premium: number, current: MedicarePlanState) => {
        setSelectedMemberFormState('plans', (prev: MedicarePlanState[]): MedicarePlanState[] => {
            return prev.map(plan => {
                if (plan.uuid === current.uuid) {
                    return { ...plan, premium }
                }
                return plan
            })
        })
    }

    const onRemovePlan = (current: MedicarePlanState) => {
        setSelectedMemberFormState('plans', (prev: MedicarePlanState[]): MedicarePlanState[] => {
            return prev.filter(plan => plan.uuid !== current.uuid)
        })
    }

    const addPlan = () => {
        setSelectedMemberFormState('plans', (prev: MedicarePlanState[]): MedicarePlanState[] => {
            return prev.concat({
                uuid: v4(),
                planType: MedicarePlanTypeNames.Other,
                premium: 0,
            })
        })
    }

    const handleMedicareNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedMemberFormState('medicareNumber', event.target.value)
    }

    const addUploadedFiles = (file: UploadedDocument) => {
        setSelectedMemberFormState('uploads', (prev: UploadedDocument[]): UploadedDocument[] => {
            return prev.concat(file)
        })
    }

    const onBeforeCloseForm = (modalApi: ModalAPI) => () => {
        const unsavedMembers = getUnsavedMembers()
        if (unsavedMembers.length > 0) {
            modalApi.open()
        } else {
            onClose()
        }
    }

    const getUnsavedMembers = () => {
        return Object.values(form.members).filter(member => member.touched).map(member => member.person.FullName)
    }

    const onSubmitForm = async () => {
        if (selectedData.uploads.length === 0) {
            setError(
                'Please upload at least one document as evidence for your Medicare monthly premiums.'
            )
            return
        }
        setConfirming(true)
        const payload = {
            personId: form.selectedMemberId,
            enrollmentPublicKey: enrollment.Id,
            personPublicKey: employee.Id,
            medicareNumber: selectedData.medicareNumber,
            medicareCoverage: selectedData.plans.map(({ uuid, ...rest }) => ({ ...rest })), // need to omit uuid or API will reject
            documents: selectedData.uploads,
            insuredName: selectedData.person.FullName,
            insuredId: selectedData.person.Id,
        }
        await onSubmit(payload)
        setConfirming(false)
        setSelectedMemberFormState('touched', false)
    }

    return (
        <Stack space={10}>
            {coverage.coverageDate && (
                <>
                    <Typography
                        style={{
                            position: 'absolute',
                            fontSize: '20px',
                            fontWeight: '700',
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            textAlign: 'left',
                            top: '-9px',
                        }}
                    >
                        Medicare
                    </Typography>
                    <Typography
                        style={{
                            backgroundColor: '#FCE9E9',
                            padding: '10px 15px',
                            margin: '30px 0 10px 0',
                            borderRadius: '10px',
                            color: '#ba8a8a',
                        }}
                    >
                        Medicare needs to be complete by{' '}
                        {coverage.coverageDate} or there could be significant
                        penalties. Link to penalties?
                    </Typography>
                </>
            )}
            <select
                style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    marginBottom: '10px',
                }}
                value={form.selectedMemberId}
                onChange={onMemberChange}
            >
                {eligibleMembers.map((member) => (
                    <option key={member.Id} value={member.Id}>
                        {member.FullName} - {member.Relationship}
                    </option>
                ))}
            </select>

            <Typography bold size={14}>
                Medicare number
            </Typography>
            <Input
                placeholder="0000-000-0000"
                style={{ width: '135px' }}
                name="Medicare number"
                onChange={handleMedicareNumberChange}
                size="middle"
                value={selectedData.medicareNumber}
                maxLength={12}
            />
            <Typography>
                This can be found on your account on{' '}
                <Typography
                    color="info"
                    as="a"
                    onClick={() => window.open('https://www.medicare.gov', 'blank')}
                >
                    Medicare.gov
                </Typography>
                , your Medicare Premium Bill, or on your Social Security Administration (SSA) account.{' '}
                <Typography
                    color="info"
                    as="a"
                    onClick={() =>
                        window.open(
                            'https://www.medicare.gov/basics/get-started-with-medicare/using-medicare/your-medicare-card',
                            'blank'
                        )
                    }
                >
                    Learn more
                </Typography>
            </Typography>

            {selectedData.plans.map((plan, index) => {
                return (
                    <PlanBlock
                        key={plan.uuid}
                        count={index}
                        plan={plan}
                        onRemovePlan={onRemovePlan}
                        onPlanTypeChange={onPlanTypeChange}
                        onPremiumChange={onPremiumChange}
                    />
                )
            })}

            {selectedData.plans.length > 0 && (
                <Typography color="primary">
                    If you have more than one Medicare plan or supplement, click Add Plan to provide this
                    additional information for each.
                </Typography>
            )}

            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    color="primary"
                    icon={<PlusOutlined />}
                    onClick={addPlan}
                    style={{ width: 'fit-content' }}
                >
                    Add Plan
                </Button>

                <Typography bold size={14}>
                    Monthly total:{' '}
                    {currencyFormatter(
                        selectedData.plans.reduce((prev, arg) => {
                            return prev + arg.premium
                        }, 0)
                    )}
                </Typography>
            </Stack>

            <Typography bold size={14}>
                Upload verification document
            </Typography>
            <Typography style={{ marginBottom: '10px' }}>
                A photo or scan of your Medicare Premium Bill.{' '}
                <Typography
                    color="info"
                    as="a"
                    onClick={() =>
                        window.open(
                            'https://s3.us-east-1.amazonaws.com/assets.hixme.com/enrollme-materials/Sample+Medicare+Documents.pdf',
                            'blank'
                        )
                    }
                >
                    See sample documents.
                </Typography>
            </Typography>
            <input style={{ display: 'none' }} ref={inputFile} type="file" onChange={handleUpload} />
            <Button
                color="primary"
                icon={<UploadOutlined />}
                onClick={() => inputFile.current.click()}
                style={{ width: 'fit-content' }}
            >
                {uploading ? 'Uploading...' : 'Upload'}
            </Button>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {selectedData.uploads.map((file, index) => {
                    return (
                        <a
                            href={file.preSignedUrl}
                            download={file.key}
                            target="_blank"
                            style={{ color: '#acacac', marginBottom: '10px' }}
                            key={index}
                        >
                            {file.key.split('/').pop()}
                        </a>
                    )
                })}
            </div>
            {error && (
                <Typography color="error" as="p" style={{ textAlign: 'center' }}>
                    {error}
                </Typography>
            )}
            <Stack direction="row" space={15} style={{ justifyContent: 'flex-end' }}>
                <Modal
                    motionProps={{ style: { zIndex: 9999 } }}
                    wrapper={{ style: { borderRadius: 8, padding: '12px 16px' } }}
                    parentNode={document.body}
                    trigger={
                        (modalApi) => (
                            <Button size="large" style={{ width: '120px' }} onClick={onBeforeCloseForm(modalApi)}>
                                <Typography size={14}>Close</Typography>
                            </Button>
                        )
                    }
                >
                    {({ close: closeWarningModal }) =>
                        <Stack space={8} style={{ padding: 16 }}>
                            <Typography size={14}>
                                There are unsaved changes for
                            </Typography>
                            <Stack direction='col' space={8} style={{ paddingInline: 24 }}>
                                {
                                    getUnsavedMembers().map((name) => <Typography key={name} size={14} bold>{name}</Typography>)
                                }
                            </Stack>
                            <Typography size={14}>Are you sure you want to discard changes for these members?</Typography>
                            <Stack direction='row' space={8} style={{ justifyContent: 'space-between', paddingTop: 8 }}>
                                <Button danger onClick={() => {
                                    closeWarningModal();
                                    onClose();
                                }}>Discard All Changes</Button>
                                <Button color='primary' onClick={() => {
                                    closeWarningModal()
                                }}>Go Back</Button>
                            </Stack>
                        </Stack>
                    }
                </Modal>
                <Button
                    size="large"
                    type="primary"
                    disabled={confirming}
                    loading={confirming}
                    style={{ width: '120px' }}
                    onClick={onSubmitForm}
                >
                    <Typography size={14}>Confirm</Typography>
                </Button>
            </Stack>
        </Stack>
    )
}
