import { v4 } from 'uuid'
import { MedicarePlanFormState, MedicareCoverageDetail, MedicarePerson, MedicarePlanTypeNames } from "./MedicarePlanForm"

export const getInitialMedicarePlanFormState = (
    coverages: MedicareCoverageDetail[],
    employee: MedicarePerson,
    dependents: MedicarePerson[],
): MedicarePlanFormState | null => {
    if (coverages.length <= 0) {
        return null
    }

    const initialFormState: MedicarePlanFormState = {
        selectedMemberId: coverages[0].personId,
        members: {},
    }

    coverages.forEach(coverage => {
        if (coverage.personId === employee.Id) {
            initialFormState.selectedMemberId = coverage.personId
        }

        initialFormState.members[coverage.personId] = {
            medicareNumber: coverage.medicareNumber,
            person: dependents.find((dependent) => dependent.Id === coverage.personId) ?? employee,
            plans: coverage.medicareCoverage?.map(plan => ({
                uuid: v4(),
                planType: (plan.planType as string) === '' ? MedicarePlanTypeNames.Other : plan.planType,
                premium: plan.premium,
            })) ?? [],
            uploads: coverage.documents ?? [],
            touched: false,
        }
    })

    return initialFormState;
}